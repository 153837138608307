@use '../base/vars' as *;

.shop_introduction{
    .shop_introduction_inner{
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 20px;
        
        h2{
            margin-top: 60px;
            @include rem(24);
            line-height: 1.5;
            font-weight: normal;
        }
        
        .shop_introduction_wrap{
            margin-top: 60px;
            display: grid;
            grid-template-columns: 2fr 1fr;
            gap: 30px;
            
            .shop_introduction_img{
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            
            .shop_introduction_text{
                p{
                    @include rem(15);
                    line-height: 2;
                }
            }
        }
        
        .shop_introduction_items{
            margin-top: 40px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 30px;
            
            & + p{
                margin-top: 10px;
                @include rem(14);
                line-height: 1.8;
            }
        }
        
        
        & + .shop_introduction_inner{
            margin-top: 100px;
            
            h2{
                margin-top: 0;
            }
        }
        
        
        .shop_introduction_foot{
            margin-top: 60px;
            // display: grid;
            // grid-template-columns: repeat(2, 1fr);
            // gap: 40px;
            
            img{
                width: 100%;
            }
            
            p{
                margin-top: 20px;
                @include rem(16);
                line-height: 2;
                
                small{
                    @include rem(14);
                    line-height: 1.8;
                }
            }
        }
    }
}

.shop_info{
    background-color: #EFEFEF;
    
    .shop_info_inner{
        max-width: 980px;
        margin: 60px auto 0;
        padding: 60px 20px;
        
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 40px;
    }
    
    .shop_info_map{
        iframe{
            width: 100%;
            height: auto;
            aspect-ratio: 4 / 3;
        }
    }
    
    .shop_info_text{
        img{
            width: 100%;
            max-width: 140px;
            height: auto;
        }
        
        p{
            margin-top: 20px;
            @include rem(16);
            line-height: 2;
        }
    }
}
