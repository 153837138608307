/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/
html {
  box-sizing: border-box;
  overflow-y: scroll; /* All browsers without overlaying scrollbars */
  -webkit-text-size-adjust: 100%; /* iOS 8+ */
}

*,
::before,
::after {
  box-sizing: inherit;
}

::before,
::after {
  text-decoration: inherit; /* Inherit text-decoration and vertical align to ::before and ::after pseudo elements */
  vertical-align: inherit;
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

input, select {
  vertical-align: middle;
}

input[type=button], input[type=submit] {
  -webkit-appearance: none;
}

body {
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "BIZ UDPGothic", Meiryo, sans-serif;
}

input,
select,
button,
textarea {
  box-sizing: border-box;
}

input[type=text],
input[type=password],
input[type=tel],
input[type=number],
input[type=submit],
input[type=button],
input[type=reset],
textarea,
button,
select {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
}

input[type=submit],
input[type=button],
input[type=reset],
button,
label[for] {
  cursor: pointer;
}

::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
}
.header .header_inner {
  display: flex;
  align-items: center;
  padding: 20px;
}
.header .header_inner .tools {
  display: flex;
  align-items: center;
  gap: 20px;
  list-style: none;
}
.header .header_inner .tools li a:hover {
  opacity: 0.7;
}
.header .header_inner .tools li a img {
  width: 24px;
  height: 24px;
  object-fit: contain;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
}
.header .header_inner .drawer {
  margin-left: auto;
}
.header .header_inner .drawer:hover {
  opacity: 0.7;
}
.header .header_inner .drawer img {
  width: 46px;
  height: 46px;
  object-fit: contain;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
  vertical-align: bottom;
}

.nav {
  position: fixed;
  left: 100%;
  top: 0;
  z-index: 99;
  width: 350px;
  height: 100vh;
  background-color: #413C3B;
  overflow: scroll;
  transition: left 0.4s;
}
.nav.open {
  left: calc(100% - 350px);
}
.nav .close {
  position: absolute;
  right: 20px;
  top: 30px;
}
.nav .close img {
  width: 46px;
  height: 46px;
  object-fit: contain;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
  vertical-align: bottom;
}
.nav .close:hover {
  opacity: 0.7;
}
.nav .nav_inner {
  padding: 30px 20px;
}
.nav .nav_inner .nav_head img {
  width: 100%;
  max-width: 80px;
  height: auto;
}
.nav .nav_inner .nav_main {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.nav .nav_inner .nav_main li a {
  color: #fff;
  text-decoration: none;
}
.nav .nav_inner .nav_main li a:hover {
  opacity: 0.7;
  text-decoration: underline;
}
.nav .nav_inner .nav_main li ul {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-left: 2em;
}
.nav .nav_inner .nav_main li ul li {
  list-style: disc;
  color: #fff;
}

.main .firstview {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.main .firstview .slider img {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.introduction .introduction_head {
  max-width: 980px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 60px 20px;
}
.introduction .introduction_head .section_title {
  font-size: 1.375rem;
  line-height: 1.7;
  font-weight: normal;
}
.introduction .introduction_head .introduction_text {
  font-size: 1rem;
  line-height: 2;
}
.introduction .introduction_about {
  background-color: #F1F0F0;
}
.introduction .introduction_about .about_hiba_inner {
  max-width: 980px;
  margin: 0 auto;
  padding: 60px 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}
.introduction .introduction_about .about_hiba_inner .about_hiba {
  display: grid;
  grid-template-columns: 150px 1fr;
  align-items: center;
  gap: 20px;
}
.introduction .introduction_about .about_hiba_inner .about_hiba h3 {
  font-size: 1.125rem;
  font-weight: normal;
}
.introduction .introduction_about .about_hiba_inner .about_hiba .about_hiba_img {
  text-align: right;
}
.introduction .introduction_about .about_hiba_inner .about_hiba img {
  width: 100%;
  max-width: 100px;
  height: auto;
}
.introduction .introduction_about .about_hiba_inner .about_hiba p {
  font-size: 0.9375rem;
  line-height: 2;
}

.img_links a:hover {
  opacity: 0.7;
}
.img_links img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

.hibaya_items {
  background-color: #413C3B;
  padding: 60px 0;
}
.hibaya_items .hibaya_items_inner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}
.hibaya_items .hibaya_items_inner h2 {
  font-size: 1.375rem;
  text-align: center;
  color: #fff;
  font-weight: normal;
}
.hibaya_items .hibaya_items_wrap {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 50px 10px;
  margin-top: 80px;
}
.hibaya_items .hibaya_items_wrap .hibaya_item a {
  color: #fff;
  text-decoration: none;
}
.hibaya_items .hibaya_items_wrap .hibaya_item a:hover {
  opacity: 0.7;
}
.hibaya_items .hibaya_items_wrap .hibaya_item a h3 {
  font-weight: normal;
  font-size: 1rem;
  font-weight: normal;
  margin-bottom: 10px;
}
.hibaya_items .hibaya_items_wrap .hibaya_item a img {
  width: 100%;
  height: auto;
}

.footer {
  margin-top: 120px;
}
.footer .footer_inner .footer_head {
  max-width: 1200px;
  margin: 60px auto 0;
  border-top: solid 1px #ccc;
}
.footer .footer_inner .footer_head ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.footer .footer_inner .footer_head ul li {
  list-style: none;
}
.footer .footer_inner .footer_head ul li a {
  display: block;
  width: 100%;
  padding: 50px 20px;
  text-align: center;
  font-size: 1.125rem;
  color: #000;
  text-decoration: none;
}
.footer .footer_inner .footer_head ul li a:hover {
  opacity: 0.7;
}
.footer .footer_inner .footer_main {
  display: flex;
  background-color: #B5B5B6;
  padding: 60px 20px;
}
.footer .footer_inner .footer_main .links {
  margin-right: auto;
}
.footer .footer_inner .footer_main .links ul {
  display: flex;
  align-items: center;
  gap: 20px;
  list-style: none;
}
.footer .footer_inner .footer_main .links ul li a {
  text-decoration: none;
  color: #fff;
}
.footer .footer_inner .footer_main .links ul li a:hover {
  opacity: 0.7;
}
.footer .footer_inner .footer_main .links ul li a img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.footer .footer_inner .footer_main .footer_foot {
  text-align: right;
}
.footer .footer_inner .footer_main .footer_foot ul li {
  list-style: none;
}
.footer .footer_inner .footer_main .footer_foot ul li a {
  color: #fff;
  text-decoration: none;
}
.footer .footer_inner .footer_main .footer_foot .copy {
  margin-top: 20px;
  color: #fff;
  font-size: 0.625rem;
}

.shop_introduction .shop_introduction_inner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}
.shop_introduction .shop_introduction_inner h2 {
  margin-top: 60px;
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: normal;
}
.shop_introduction .shop_introduction_inner .shop_introduction_wrap {
  margin-top: 60px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 30px;
}
.shop_introduction .shop_introduction_inner .shop_introduction_wrap .shop_introduction_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.shop_introduction .shop_introduction_inner .shop_introduction_wrap .shop_introduction_text p {
  font-size: 0.9375rem;
  line-height: 2;
}
.shop_introduction .shop_introduction_inner .shop_introduction_items {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}
.shop_introduction .shop_introduction_inner .shop_introduction_items + p {
  margin-top: 10px;
  font-size: 0.875rem;
  line-height: 1.8;
}
.shop_introduction .shop_introduction_inner + .shop_introduction_inner {
  margin-top: 100px;
}
.shop_introduction .shop_introduction_inner + .shop_introduction_inner h2 {
  margin-top: 0;
}
.shop_introduction .shop_introduction_inner .shop_introduction_foot {
  margin-top: 60px;
}
.shop_introduction .shop_introduction_inner .shop_introduction_foot img {
  width: 100%;
}
.shop_introduction .shop_introduction_inner .shop_introduction_foot p {
  margin-top: 20px;
  font-size: 1rem;
  line-height: 2;
}
.shop_introduction .shop_introduction_inner .shop_introduction_foot p small {
  font-size: 0.875rem;
  line-height: 1.8;
}

.shop_info {
  background-color: #EFEFEF;
}
.shop_info .shop_info_inner {
  max-width: 980px;
  margin: 60px auto 0;
  padding: 60px 20px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 40px;
}
.shop_info .shop_info_map iframe {
  width: 100%;
  height: auto;
  aspect-ratio: 4/3;
}
.shop_info .shop_info_text img {
  width: 100%;
  max-width: 140px;
  height: auto;
}
.shop_info .shop_info_text p {
  margin-top: 20px;
  font-size: 1rem;
  line-height: 2;
}

.original_firstview {
  width: 100vw;
  height: 100vh;
  background-image: url(../img/original/firstview.webp);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.original_firstview h2 {
  text-align: center;
  font-size: 10vw;
  line-height: 1.8;
  color: #fff;
  font-weight: normal;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
}
.original_firstview h2 span {
  display: block;
  font-size: 4vw;
}
.original_firstview h2 small {
  display: block;
  font-size: 2vw;
}

.single .single_inner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}
.single .single_inner + .single_inner {
  margin-top: 120px;
}
.single .single_inner .single_title {
  margin-top: 60px;
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: normal;
}
.single .single_inner .single_inner_head_img {
  margin-top: 60px;
}
.single .single_inner .single_inner_head_img img {
  width: 100%;
  height: auto;
}
.single .single_col50 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  margin-top: 60px;
}
.single .single_col3-1 {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 40px;
  margin-top: 60px;
}
.single .single_item p {
  font-size: 1rem;
  line-height: 1.8;
}
.single .single_item p + img {
  margin-top: 30px;
}
.single .single_item img {
  width: 100%;
  height: auto;
}
.single .single_item img + img {
  margin-top: 10px;
}
.single .single_item .original_item + p {
  margin-top: 20px;
}

.info {
  max-width: 1200px;
  margin: 60px auto 0;
  padding: 0 20px;
}
.info h3 {
  font-size: 1.25rem;
  margin-bottom: 10px;
}
.info p {
  font-size: 1rem;
  line-height: 1.8;
}

.product_firstview {
  width: 100vw;
  height: 100vh;
  background-image: url(../img/original/firstview.webp);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product_firstview.product01 {
  background-image: url(../img/product/product01/img01.webp);
}
.product_firstview.product02 {
  background-image: url(../img/product/product02/img01.webp);
}
.product_firstview.product03 {
  background-image: url(../img/product/product03/img01.webp);
}
.product_firstview.product04 {
  background-image: url(../img/product/product04/img01.webp);
}
.product_firstview.product05 {
  background-image: url(../img/product/product05/img01.webp);
}
.product_firstview.product06 {
  background-image: url(../img/product/product06/img01.webp);
}
.product_firstview.product07 {
  background-image: url(../img/product/product07/img01.webp);
}
.product_firstview.product08 {
  background-image: url(../img/product/product08/img01.webp);
}
.product_firstview.product09 {
  background-image: url(../img/product/product09/img01.webp);
}
.product_firstview.product10 {
  background-image: url(../img/product/product10/img01.webp);
}
.product_firstview.product11 {
  background-image: url(../img/product/product11/img01.webp);
}
.product_firstview.product12 {
  background-image: url(../img/product/product12/img01.webp);
}
.product_firstview.product13 {
  background-image: url(../img/product/product13/img01.webp);
}
.product_firstview h2 {
  width: 100%;
  max-width: 1200px;
  text-align: left;
  font-size: 12vw;
  line-height: 1.5;
  color: #fff;
  font-weight: normal;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
}
.product_firstview h2 span {
  display: block;
  font-size: 4vw;
  text-indent: 0.5em;
}