@use '../base/vars' as *;

.original_firstview{
    width: 100vw;
    height: 100vh;
    background-image: url(../img/original/firstview.webp);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    h2{
        span{
            display: block;
            font-size: 4vw;
        }
        
        text-align: center;
        font-size: 10vw;
        line-height: 1.8;
        color: #fff;
        font-weight: normal;
        filter: drop-shadow(0 0 5px rgba(#000, .3));
        
        small{
            display: block;
            font-size: 2vw;
        }
    }
}





.single{
    .single_inner{
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 20px;
        
        & + .single_inner{
            margin-top: 120px;
        }
        
        .single_title{
            margin-top: 60px;
            @include rem(24);
            line-height: 1.5;
            font-weight: normal;
        }
        
        .single_inner_head_img{
            margin-top: 60px;
            
            img{
                width: 100%;
                height: auto;
            }
        }
    }
    
    .single_col50{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
        margin-top: 60px;
    }
    
    .single_col3-1{
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 40px;
        margin-top: 60px;
    }
    
    .single_item{
        p{
            @include rem(16);
            line-height: 1.8;
            
            & + img{
                margin-top: 30px;
            }
        }
        
        img{
            width: 100%;
            height: auto;
            
            & + img{
                margin-top: 10px;
            }
        }
        
        .original_item + p{
            margin-top: 20px;
        }
    }
}

.info{
    max-width: 1200px;
    margin: 60px auto 0;
    padding: 0 20px;
    
    h3{
        @include rem(20);
        margin-bottom: 10px;
    }
    
    p{
        @include rem(16);
        line-height: 1.8;
    }
}
