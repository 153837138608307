@use '../base/vars' as *;

.wrap{
    //overflow-x: hidden;
}










.header{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    
    .header_inner{
        display: flex;
        align-items: center;
        padding: 20px;
        
        .tools{
            display: flex;
            align-items: center;
            gap: 20px;
            
            list-style: none;
            
            li{
                a{
                    &:hover{
                        opacity: .7;
                    }
                    
                    img{
                        width: 24px;
                        height: 24px;
                        object-fit: contain;
                        filter: drop-shadow(0 0 5px rgba(#000, .3));
                    }
                }
            }
        }
        
        .drawer{
            margin-left: auto;
            
            &:hover{
                opacity: .7;
            }
            
            img{
                width: 46px;
                height: 46px;
                object-fit: contain;
                filter: drop-shadow(0 0 5px rgba(#000, .3));
                vertical-align: bottom;
            }
        }
    }
}









.nav{
    position: fixed;
    left: 100%;
    top: 0;
    z-index: 99;
    width: 350px;
    height: 100vh;
    background-color: #413C3B;
    overflow: scroll;
    transition: left .4s;
    
    &.open{
        left: calc(100% - 350px);
    }
    
    .close{
        position: absolute;
        right: 20px;
        top: 30px;
        // color: #fff;
        // @include rem(18);
        
        img{
            width: 46px;
            height: 46px;
            object-fit: contain;
            filter: drop-shadow(0 0 5px rgba(#000, .3));
            vertical-align: bottom;
        }
        
        
        &:hover{
            opacity: .7;
        }
    }
    
    .nav_inner{
        padding: 30px 20px;
        
        .nav_head{
            img{
                width: 100%;
                max-width: 80px;
                height: auto;
            }
        }
        
        .nav_main{
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            
            li{
                a{
                    color: #fff;
                    text-decoration: none;
                    
                    &:hover{
                        opacity: .7;
                        text-decoration: underline;
                    }
                }
                
                ul{
                    padding-top: 20px;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    padding-left: 2em;
                    
                    li{
                        list-style: disc;
                        color: #fff;
                    }
                }
            }
        }
    }
}










.main{
    .firstview{
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        
        .slider{
            img{
                width: 100vw;
                height: 100vh;
                object-fit: cover;
            }
        }
    }
}











.introduction{
    .introduction_inner{
        
    }
    
    .introduction_head{
        max-width: 980px;
        margin: 0 auto;
        
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        padding: 60px 20px;
        
        .section_title{
            @include rem(22);
            line-height: 1.7;
            font-weight: normal;
        }
        
        .introduction_text{
            @include rem(16);
            line-height: 2;
        }
    }
    
    .introduction_about{
        background-color: #F1F0F0;
        
        .about_hiba_inner{
            max-width: 980px;
            margin: 0 auto;
            padding: 60px 20px;
            
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 30px;
            
            .about_hiba{
                display: grid;
                grid-template-columns: 150px 1fr;
                align-items: center;
                gap: 20px;
                
                h3{
                    @include rem(18);
                    font-weight: normal;
                }
                
                .about_hiba_img{
                    text-align: right;
                }
                
                img{
                    width: 100%;
                    max-width: 100px;
                    height: auto;
                }
                
                p{
                    @include rem(15);
                    line-height: 2;
                }
            }
        }
    }
}


.img_links{
    
    a{
        &:hover{
            opacity: .7;
        }
    }
    
    img{
        width: 100%;
        height: auto;
        vertical-align: bottom;
    }
}

.hibaya_items{
    background-color: #413C3B;
    padding: 60px 0;
    
    .hibaya_items_inner{
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 20px;
        
        h2{
            @include rem(22);
            text-align: center;
            color: #fff;
            font-weight: normal;
        }
    }
    
    .hibaya_items_wrap{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 50px 10px;
        margin-top: 80px;
        
        .hibaya_item{
            a{
                color: #fff;
                text-decoration: none;
                
                &:hover{
                    opacity: .7;
                }
                
                h3{
                    font-weight: normal;
                    @include rem(16);
                    font-weight: normal;
                    margin-bottom: 10px;
                }
                
                img{
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}








.footer{
    margin-top: 120px;
    
    .footer_inner{
        .footer_head{
            max-width: 1200px;
            margin: 60px auto 0;
            border-top: solid 1px #ccc;
            
            ul{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                
                li{
                    list-style: none;
                    
                    a{
                        display: block;
                        width: 100%;
                        padding: 50px 20px;
                        text-align: center;
                        @include rem(18);
                        color: #000;
                        text-decoration: none;
                        
                        &:hover{
                            opacity: .7;
                        }
                    }
                }
            }
        }
        
        .footer_main{
            display: flex;
            background-color: #B5B5B6;
            padding: 60px 20px;
            
            .links{
                margin-right: auto;
                
                ul{
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    
                    list-style: none;
                    
                    li{
                        a{
                            text-decoration: none;
                            color: #fff;
                            
                            &:hover{
                                opacity: .7;
                            }
                            
                            img{
                                width: 24px;
                                height: 24px;
                                object-fit: contain;
                            }
                        }
                    }
                }
            }
            
            .footer_foot{
                text-align: right;
                
                ul{
                    li{
                        list-style: none;
                        
                        a{
                            color: #fff;
                            text-decoration: none;
                        }
                    }
                }
                
                .copy{
                    margin-top: 20px;
                    color: #fff;
                    @include rem(10);
                }
            }
        }
    }
}
