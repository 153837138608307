@use '../base/vars' as *;

.product_firstview{
    width: 100vw;
    height: 100vh;
    background-image: url(../img/original/firstview.webp);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    &.product01{
        background-image: url(../img/product/product01/img01.webp);
    }

    &.product02{
        background-image: url(../img/product/product02/img01.webp);
    }

    &.product03{
        background-image: url(../img/product/product03/img01.webp);
    }

    &.product04{
        background-image: url(../img/product/product04/img01.webp);
    }

    &.product05{
        background-image: url(../img/product/product05/img01.webp);
    }

    &.product06{
        background-image: url(../img/product/product06/img01.webp);
    }

    &.product07{
        background-image: url(../img/product/product07/img01.webp);
    }

    &.product08{
        background-image: url(../img/product/product08/img01.webp);
    }

    &.product09{
        background-image: url(../img/product/product09/img01.webp);
    }

    &.product10{
        background-image: url(../img/product/product10/img01.webp);
    }

    &.product11{
        background-image: url(../img/product/product11/img01.webp);
    }

    &.product12{
        background-image: url(../img/product/product12/img01.webp);
    }

    &.product13{
        background-image: url(../img/product/product13/img01.webp);
    }
    
    h2{
        width: 100%;
        max-width: 1200px;
        
        span{
            display: block;
            font-size: 4vw;
            text-indent: .5em;
        }

        text-align: left;
        font-size: 12vw;
        line-height: 1.5;
        color: #fff;
        font-weight: normal;
        filter: drop-shadow(0 0 5px rgba(#000, .3));
    }
}
